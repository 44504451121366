<template>
  <customer-member-tab>
    <template v-slot:content>
      <div class="mt-4">
        <template>
          <div class="d-flex justify-start">
            <v-tabs class="response-tab" v-model="tab" align-with-title>
              <v-tabs-slider color="#13ACE0"></v-tabs-slider>
              <v-tab
                class="page-title-tab"
                v-for="(item, i) in items"
                :to="item.to"
                :key="i"
                exact
                >{{ item.title }}
              </v-tab>
            </v-tabs>
          </div>
          <div class="mx-10">
            <div><ExperienceBasedAccommodationCustomers /></div>
          </div>
        </template>
      </div>
    </template>
  </customer-member-tab>
</template>

<script>
import CustomerMemberTab from '../../customerMemberTab.vue';
import ExperienceBasedAccommodationCustomers from '@/views/customer/response/ExperienceBasedAccommodationCustomers/ExperienceBasedAccommodationCustomers.vue';

export default {
  components: { 
    CustomerMemberTab,
    ExperienceBasedAccommodationCustomers
  },
  name: 'responseAll',
  data() {
    return {
      // Tabs
      tab: null,
      items: [
        {
          tab: 1,
          title: '全て',
          to: '/customer/prospect-list', // 241
        },
        {
          tab: 2,
          title: '体験宿泊顧客',
          to: '/customer/prospect-list/experience-based-accommodation-customers', // 242
        },
        {
          tab: 3,
          title: '対面顧客',
          to: '/customer/prospect-list/face-to-face-customer', // 243
        },
      ],
    }
  }
}
</script>

<style scoped lang="scss">


.response-tab::v-deep {
  .v-tabs-bar {
    height: 35px;
  }

  .v-tab {
    color: #13ace0 !important;
    width: 205px;
    height: 35px;
    text-align: center;
    border-top: solid 1px #ccc;
    border-bottom: solid 1px #ccc;
    border-left: solid 1px #ccc;

    &:last-child {
      border-right: solid 1px #ccc;
    }

    &--active {
      background-color: #13ace0 !important;
      color: #fff !important;
      border-color: #13ace0 !important;
    }
  }

  .v-tabs-slider-wrapper,
  .v-slide-group__prev,
  .v-slide-group__next {
    display: none;
  }
}
</style>
